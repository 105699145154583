import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import '../styles/progress.css'
import SchoolImage from '../images/sol (1).jpg'
const Progress = () => (
    <Layout>
        <Seo title="Progress" />
        <div className="progress-background">
          <div id="school-image"></div>
            <div className="progress-container">
            <h1 className="progress-title">Our Progress</h1>
                <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
                    rel="stylesheet"></link>
                <div className="timeline">
                    <div className="timeline-container primary">
                        <div className="timeline-icon">
                            <span class="material-icons" id="card-icon">
                                attach_money
                            </span>
                        </div>
                        <div className="timeline-body">
                            <h4 className="timeline-title"><span className="badge">$250,000+ Invested</span></h4>
                            <p>Stones of Light Education Foundation has invested $250,000 for Guatemalan youth to receive an education!</p>

                        </div>
                    </div>


                    <div className="timeline-container warning">
                        <div className="timeline-icon">
                            <span class="material-icons" id="card-icon">
                                favorite_border
                            </span>
                        </div>
                        <div className="timeline-body">
                            <h4 className="timeline-title"><span className="badge">Thousands of Volunteer hours</span></h4>
                            <p>Thousands of hours on the ground helping to improve the quality and scope of Guatemalan Education for those living in Extreme Poverty.</p>
                        </div>
                    </div>
                    <div className="timeline-container info">
                        <div className="timeline-icon">
                            <span class="material-icons" id="card-icon">
                                handshake
                            </span>

                        </div>
                        <div className="timeline-body">
                            <h4 className="timeline-title"><span className="badge">Colegio Del Futuro Partnership</span></h4>
                            <p>Partnered with Colegio Del Futuro many years ago, bringing the (Guatemalan based - Ministry of Education Certified) online/live mentored education system to thousands of students in Central America and beyond.</p>
                        </div>
                    </div>

                    <div className="timeline-container warning">
                        <div className="timeline-icon">
                            <span class="material-icons" id="card-icon">
                                school
                            </span>
                        </div>
                        <div className="timeline-body">
                            <h4 className="timeline-title"><span className="badge">Snow College Alliance</span></h4>
                            <p>Created an alliance with Snow College, the number one Junior college in the United States, with a number of students graduated or currently working on their degrees!</p>
                        </div>
                    </div>
                    <div className="timeline-container primary">
                        <div className="timeline-icon">
                            <span class="material-icons" id="card-icon">
                                <span class="material-icons-outlined">
                                    house_siding
                                </span>
                            </span>
                        </div>
                        <div className="timeline-body">
                            <h4 className="timeline-title"><span className="badge">Opened Chisec School 2021</span></h4>
                            <p>Opened a new school in Chisec, Guatemala 2021.</p>
                        </div>
                    </div>
                    <div className="timeline-container warning">
                        <div className="timeline-icon">
                            <span class="material-icons" id="card-icon">
                                <span class="material-icons-outlined">
                                    compost
                                </span>
                            </span>
                        </div>
                        <div className="timeline-body">
                            <h4 className="timeline-title"><span className="badge">Impact Investing Alliance</span></h4>
                            <p>Established an alliance with Las Azucenas Tropical hardwoods to deliver amazing investment returns that help heal the planet and provide education. <a style={{color: 'white'}} href="https://goodalways.com" target="_blank">Learn More</a> </p>
                        </div>
                    </div>
                    <div className="timeline-container info">
                        <div className="timeline-icon">
                            <span class="material-icons" id="card-icon">
                                light_mode
                            </span>
                        </div>
                        <div className="timeline-body">
                            <h4 className="timeline-title"><span className="badge">Exciting Opportunities</span></h4>
                            <p>There are exciting new opportunities, in today’s global environment, to improve the lives of children who are living in extreme poverty through education. The future is filled with light and hope!</p>
                        </div>
                    </div>


                </div>
            </div>
        </div>


    </Layout>
)

export default Progress
